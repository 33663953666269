<template>
  <div>
    <join-card heading="Reset Password" :include-name="false">
      <div class="my-4 text-blue-400">{{ formMessage }}</div>
      <form class="w-full" action="" @submit.prevent="submitForm">

        <div class="my-6 w-full">


          <div class="w-full font-bold text-sm italic text-center mb-4 bg-red-50 rounded py-2 px-4 text-red-400">You will get an OTP to {{ $route.query.phone }} within minutes</div>

          <div class="mb-2">
            <label>OTP</label>
            <input-text :variant="this.hasFormError('code') ? 'danger':'primary'"
                        placeholder="Type your OTP" size="md" class="w-full" v-model="password_data.code"/>
            <show-error :errors="formErrors" name="code"></show-error>
          </div>

          <div class="mb-3">
            <label>New Password</label>
            <input-text label="Old Password" password-eye
                        :variant="this.hasFormError('password') ? 'danger':'primary'"
                        type="password"
                        placeholder="Type a new password" size="md" class="w-full" v-model="password_data.password" />
            <show-error :errors="formErrors" name="password"></show-error>
          </div>


        </div>

        <div class="text-center">
          <btn type="submit" variant="info" :loading="loading">Submit</btn>
        </div>

      </form>
    </join-card>
  </div>
</template>

<script>
import JoinCard from "../components/joincard.vue";
import InputText from "../../../../components/input/input-text"
import Btn from "../../../../components/btn";
import ShowError from "../../../../components/show-error";
import StatusMessage from "../../../../components/status-message";

export default {
  components: {StatusMessage, ShowError, Btn, JoinCard, InputText },
  name: "forgot-password",
  data() {
    return {
      formErrors: {},
      password_data: {},
      loading: false,
    }
  },

  methods: {
    submitForm( ) {
      this.loading = true;
      this.formErrors = [];

      this.password_data.phone = this.$route.query.phone;

      this.$store.dispatch('student/setNewPassword',
          {
            data: this.password_data,

            error: response => this.setFormMessage(response.data.message),

            has_errors: errors => this.setFormErrors(errors),

            success: (response) => {

              const data = response.data;


              this.setFormMessage( data.message );
              this.setTokenAndRedirectToNext( data );

              this.$router.push( '/my/profile' );

            }

          }
      ).finally( () => this.loading = false );

    }
  }
};
</script>

